<script lang="ts" setup>
import { computed, inject, ref, watch } from "vue"
import { getImportFromAquaId } from "@/common/aqua/TestupWrapper"
import VirtualKeyboard from "@/modules/run/components/VirtualKeyboard.vue"
import { actionVisibleInBranch } from "@/services/actionUtils"
import type { ActionDTO } from "@/types/gen";
import { TestcaseService } from "@/services/TestcaseService";

interface Props {
    device: any
    actions: any[]
    actionsLoaded: boolean
    currentAction: ActionDTO
    mode: string
    testCaseService: TestcaseService
    shouldDisableInsertMenu: boolean
    showKeyboard: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (event: "pressKey", data: any): void
    (event: "setInteraction", data: any): void
    (event: "tagAction", data: any): void
    (event: "controlAction", data: any): void
    (event: "enterInput", data: any): void
    (event: "setMode", data: any): void
    (event: "update", data: any): void
    (event: "retake"): void
    (event: "play"): void
    (event: "playall"): void
    (event: "stop"): void
    (event: "replay"): void
    (event: "reset"): void
    (event: "addVariable"): void
    (event: "syncAqua"): void
    (event: "showVideo"): void
    (event: "delete", data: ActionDTO, debranch?:boolean): void
    (event: "cutAction", data: string): void
    (event: "openKeyboard", data: boolean): void
}>()
const branch = inject('branch', '')

const wordpressBase = ref(WORDPRESS_BASE)
const showMore = ref(false)
const anchor = ref(false)
const PLUGIN_AQUA_URL = ref<string>((window as any).PLUGIN_AQUA_URL)
const OCR_ENABLED = ref<boolean>((window as any).OCR_ENABLED)

const testcase = computed(() => props.testCaseService.getTestCase())
const config = computed(() => props.testCaseService.getConfig())
const importFromAquaId = computed(() => getImportFromAquaId(testcase.value.config))
const flagIsTrue = computed(() => props.device.runtimeVariables?.FLAG === 'true' )
const flagIsFalse = computed(() => props.device.runtimeVariables?.FLAG === 'false' )
const isInBranch = computed(() => actionVisibleInBranch(props.currentAction, branch.value))

const hasDevice = computed(() => {
    return !!props.device
})

const deviceConnected = computed(() => {
    return hasDevice.value && props.device.connected
})

const noActionsOrPlaying = computed(() => {
    return !hasActions.value || props.device.playing
})

const skipBackDisabled = computed(() => {
    return noActionsOrPlaying.value || isFirst.value
})

const playDisabled = computed(() => {
    return !hasDevice.value || props.device.currentAction < 0 || props.device.playing || isEnd.value
})

const skipForwardDisabled = computed(() => {
    return noActionsOrPlaying.value || isEnd.value
})

const hasActions = computed(() => {
    return !!props.actions.length
})

const isFirst = computed(() => {
    let index = props.actions.findIndex((a: any) => a.id == props.device.currentAction)
    return index === 0
})

const canEdit = computed(() => true)

const isEnd = computed(() => {
    if (!deviceConnected.value) return false
    let isEnd =
        (props.device.currentAction === -1 && !!props.actions.length) ||
        (props.device.currentAction < 0 && props.actions.length == 0 && props.actionsLoaded)
    return isEnd
})

const canPlay = computed(() => {
    let doCanPlay = true
    if (!!props.currentAction && props.currentAction.type == "POINTER") {
        if (props.device.nextFrame) {
            doCanPlay = false
        }
        return doCanPlay
    }
    return undefined
})

function isJumpOrTagAction(action: ActionDTO): boolean {
    const inputTypes = ["JUMP", "TAG", "JUMP_IF_TRUE", "JUMP_IF_FALSE"]

    return action.inputs.some((input: any) => inputTypes.includes(input.type))
}

const retakeAnchorAreaPossible = computed(() => {
    const retakeIsForbidden = !!props.currentAction && (props.currentAction.type == "TEXT" || isJumpOrTagAction(props.currentAction))
    
    if (props.device.retakeAnchorArea && retakeIsForbidden) {
        props.device.retakeAnchorArea = false
    }

    return retakeIsForbidden
})

const hasAnchorArea = computed(() => {
    return props.currentAction ? props.currentAction.matcherId : null
})

const disableInteractionButton = computed(() => {
    return isEnd.value || retakeAnchorAreaPossible.value
})

const webBrowser = computed(() => {
    if (!config.value) return false
    const systemName = config.value.systemName
    return systemName ? systemName.includes("Website") : true
})

const mobile = computed(() => {
    if (!config.value) return false
    const systemName = config.value?.systemName
    return systemName ? systemName.includes("Mobile") : false
})

watch(
    () => props.device.retakeAnchorArea,
    () => {
        anchor.value = props.currentAction ? props.device.retakeAnchorArea : false
    }
)

function pressKey(keyType: string) {
    if (showMore.value) showMore.value = false
    emit("pressKey", keyType)
}

function setInteraction(interaction: string) {
    if (
        props.currentAction.type == "POINTER" ||
        (props.currentAction.type == "CONTROL" && props.currentAction.inputs[0]?.type == "FIND_IMAGE")
    ) {
        emit("setInteraction", interaction)
    }
}

function tagAction(actionType: string) {
    emit("tagAction", actionType)
}

function controlAction(actionType: string) {
    emit("controlAction", actionType)
}

function enterInput(inputType: string, input?: string) {
    emit("enterInput", inputType, input)
}

function changeMode(mode: string) {
    emit("setMode", mode)
}

function openMore() {
    showMore.value = !showMore.value
}

function changeDisableStatus(isDisabled: boolean) {
    const currentAction = {
        ...props.currentAction,
        disabled: isDisabled,
    }
    emit("update", currentAction)
}

function retake() {
    anchor.value = !anchor.value
    emit("retake")
}

function setTimeout() {
    const currentAction = {
        ...props.currentAction,
        showTimeout: true,
    }
    emit("update", currentAction)
}

function update() {
    emit("update", props.currentAction)
}

function isDeviceSelenium() {
    return props.testCaseService.testCase?.config?.systemName === 'Website - Chrome' || props.testCaseService.testCase?.config?.systemName === 'Playground'
}
</script>

<template lang="pug">
.run-header
    .navigation
        ul.nav.nav-tabs
            li.nav-item
                a.nav-link(:class="{ active: mode == 'play' }", @click="changeMode('play')") Play
            li.nav-item
                a.nav-link(
                    :class="{ active: mode == 'insert-default', disabled: device.playing }",
                    @click="changeMode('insert-default')"
                ) Insert
            li.nav-item
                a.nav-link(
                    :class="{ active: mode == 'insert-control', disabled: device.playing }",
                    @click="changeMode('insert-control')"
                ) Control
            li.nav-item
                a.nav-link(:class="{ active: mode == 'edit', disabled: device.playing }", @click="changeMode('edit')") Edit
            li.nav-item
                a.nav-link(:class="{ active: mode == 'data', disabled: device.playing }", @click="changeMode('data')") Data
            li.nav-item
                a.nav-link(:class="{ active: mode == 'help', disabled: device.playing }", @click="changeMode('help')") Help
        .done-button.pr-1
            slot
    .tabs
        template(v-if="mode == 'play'")
            .tab-content.tab-pane
                .button-container(
                    v-if="!device.playing",
                    :class="{ disabledButton: playDisabled }",
                    @click="$emit('playall')"
                )
                    .icon-large.fas.fa-play
                    .span-sm Play
                .button-container.playing(v-else, @click="$emit('stop')")
                    i.-bigger.fas.fa-pause-circle
                    .span-sm Stop
                .button-container(:class="{ disabledButton: playDisabled }", @click="$emit('play')")
                    .icon-large.fas.fa-step-forward.play-it-icon-size
                    .span-sm Play it
                .iconSep
                .button-container(:class="{ disabledButton: device.playing || !deviceConnected }", @click="$emit('replay')")
                    .icon-large.fas.fa-redo-alt
                    .span-sm Replay
                .button-container(:class="{ disabledButton: device.playing }", @click="$emit('reset')")
                    .icon-large.fas.fa-reply
                    .span-sm Reset
                .iconSep
        template(v-if="mode == 'insert-default'")
            .tab-content.tab-pane(
                :class="{ disabledButton: device.playing || shouldDisableInsertMenu || (!canEdit && !isEnd) }"
            )
                .iconSep

                .button-container(@click="enterInput('ENTER')", :class="{ disabledButton: !hasActions }")
                    .icon-large.fas.fa-keyboard
                    .span-sm Enter Text
                .iconSep
                .button-container(@click="enterInput('PASSWORD')", :class="{ disabledButton: !hasActions }")
                    .icon-large.fas.fa-lock
                    .span-sm Password
                .iconSep
                .button-container(@click="$emit('openKeyboard', true)", :class="{ disabledButton: !hasActions }")
                    .icon-large.fas.fa-keyboard
                    .span-sm Keyboard
                .iconSep
                .button-container(@click="enterInput('SCROLL')", :class="{ disabledButton: !hasActions }")
                    span &#8691;
                    .span-sm Scroll
                virtual-keyboard(
                    :showKeyboard="showKeyboard",
                    @closeKeyboard="$emit('openKeyboard', false)",
                    @play="$emit('play')",
                    @keypress="pressKey",
                    @text="(input) => enterInput('ENTER', input)"
                )
                .iconSep
                div(:class="{ disabledButton: !hasActions }")
                    table
                        tbody
                            tr
                                td(rowspan="2")
                                    .dropdown.dropdown-pos
                                        .button-container.moreKeys(@click="openMore", style="width: 50px")
                                            .span-sm Copy Paste
                                            .icon-large.fas.fa-chevron-down
                                        .dropdown-menu(v-if="showMore")
                                            h5.move-left More keys:
                                            .button-container.padding-reduce.move-left(@click="pressKey('SELECT_ALL')")
                                                .align-mg
                                                    .icon-small.far.fa-check-square
                                                    span.span-sm.alignMoreMenu Select All
                                            .button-container.padding-reduce.move-left(@click="pressKey('CUT')")
                                                .align-mg
                                                    .icon-small.fas.fa-cut
                                                    span.span-sm.alignMoreMenu Cut
                                            .button-container.padding-reduce.move-left(@click="pressKey('COPY')")
                                                .align-mg
                                                    .icon-small.far.fa-copy
                                                    span.span-sm.alignMoreMenu Copy
                                            .button-container.padding-reduce.move-left(@click="pressKey('PASTE')")
                                                .align-mg
                                                    .icon-small.far.fa-clone
                                                    span.span-sm.alignMoreMenu Paste
                .iconSep.icon-mg
                .button-container(@click="enterInput('WAIT')")
                    .icon-large.fas.fa-clock
                    .span-sm Wait
                .iconSep
                .button-container(v-if="webBrowser || mobile", @click="enterInput('NAVIGATE_URL')")
                    .icon-large.fa.fa-globe
                    .span-sm Open Url
                div(v-if="webBrowser")
                    table
                        tbody
                            tr
                                td
                                    .button-container.padding-reduce(@click="pressKey('NAVIGATE_REFRESH')")
                                        .span-sm &#8635; Refresh
                                td
                                    .button-container.padding-reduce.move-left(@click="pressKey('NAVIGATE_BACK')")
                                        .span-sm &#8592; Back
                            tr
                                td
                                    .button-container.padding-reduce(@click="pressKey('NAVIGATE_NEXT_TAB')")
                                        .span-sm &#8680; Next Tab
                                td
                                    .button-container.padding-reduce.move-left(@click="pressKey('NAVIGATE_FORWARD')")
                                        .span-sm &#8594; Forward
                    .span-sm.browser-actions-pos Browser Actions
                .iconSep(v-if="webBrowser")
                .button-container(@click="tagAction('MARKER')")
                    .icon-large.fas.fa-tag
                    .span-sm Tag
                .iconSep(v-if="isDeviceSelenium()")
                .button-container(@click="tagAction('PROMPT')")
                    .icon-large.fas.fa-terminal
                    .span-sm Prompt
                .custom-align
                .iconSep

        template(v-if="mode == 'insert-control'")
            .tab-content.tab-pane(:class="{ disabledButton: !canEdit && !isEnd }")
                // Group 1: "clip board"
                .button-container(@click="controlAction('EVAL')")
                    .icon-large.fas.fa-code
                    .span-sm Eval

                // Group 2: "operation"
                div
                    .button-container.padding-reduce.move-left(@click="controlAction('EVAL:EQUALS')")
                        .span-sm
                            .fas.fa-equals
                            =" Equals"
                    .button-container.padding-reduce.move-left(@click="controlAction('EVAL:ERROR')")
                        .span-sm
                            .fas.fa-bolt
                            =" Error"
                    .button-container.padding-reduce.move-left(@click="controlAction('EVAL:MICROTIME')")
                        .span-sm
                            .fas.fa-clock
                            =" Time"

                .iconSep
                .button-container(@click="controlAction('FIND_IMAGE')")
                    .icon-large.fas.fa-search
                    .span-sm Find
                .iconSep
                // Group 3: "flow"
                .button-container(@click="controlAction('JUMP')")
                    .icon-large.fas.fa-map-marker-alt
                    .span-sm Jump
                div
                    table
                        tbody
                            tr
                                td
                                    .button-container.padding-reduce.move-left.wide-item(
                                        @click="controlAction('JUMP_IF_TRUE')"
                                    )
                                        .span-sm &#8872; Jump if True
                                            .span-sm.fa.fa-flag.ml-1(v-show="flagIsTrue")
                            tr
                                td
                                    .button-container.padding-reduce.move-left.wide-item(
                                        @click="controlAction('JUMP_IF_FALSE')"
                                    )
                                        .span-sm &#8877; Jump if False
                                            .span-sm.fa.fa-flag.ml-1(v-show="flagIsFalse")

                    .span-sm.browser-actions-pos.pl-2 Flow
                .iconSep
                // Group 4:
                //-insert starts here
                div(style="height: 70px; margin-bottom: 8px;")
                    .table-clipboard
                        .table
                            .button-container.padding-reduce.justify-items-left(@click="pressKey('COPY')")
                                .span-sm
                                    .far.fa-copy
                                    = ' Copy'
                            .button-container.padding-reduce(@click="pressKey('PASTE')")
                                .span-sm
                                    .far.fa-clone
                                    = ' Paste'

                            .button-container.padding-reduce(@click="pressKey('SELECT_ALL')")
                                .span-sm
                                    .far.fa-check-square
                                    = ' Select all'

                            .button-container.padding-reduce.move-left(style="width: 70px;" @click="pressKey('CUT')")
                                .span-sm
                                    .fas.fa-cut
                                    = ' Cut'
                        .clipboard-textarea-section
                            .form-group(style="margin-bottom: 12px")
                                textarea.form-control(rows="1" style="resize: none; height: 42px;" @keydown.stop) {{ device.clipboard }}
                            .span-sm.browser-actions-pos.ml-0.mt-0 Clipboard


                .iconSep
        template(v-if="mode == 'edit'")
            .tab-content.tab-pane(:class="{ disabledButton: !canEdit && !isEnd }")
                .button-container(:class="{ disabledButton: isEnd || canPlay }", @click="$emit('play')")
                    .icon-large.fas.fa-step-forward.play-it-icon-size
                    .span-sm Play it
                .iconSep
                .button-container(v-if="isInBranch && branch" :class="{ disabledButton: isEnd }", @click="$emit('delete', currentAction, true)")
                    .icon-large.fa.fa-eye-slash
                    .span-sm Debranch
                .button-container(v-else :class="{ disabledButton: isEnd }", @click="$emit('delete', currentAction)")
                    .icon-large.fa.fa-trash
                    .span-sm Delete
                .button-container(:class="{ disabledButton: isEnd }", @click="$emit('cutAction', currentAction)")
                    .icon-large.fas.fa-cut
                    .span-sm Cut &amp; Keep
                .iconSep
                .button-container(:class="{ disabledButton: isEnd }")
                    label(@change="changeDisableStatus(false)")
                        input.form-check-input(
                            v-if="currentAction",
                            :checked="!currentAction.disabled",
                            type="radio",
                            style="margin-left: 0.5px; margin-top: -1px"
                        )
                        .span-sm(style="margin-left: 25px") Enabled
                    label(@change="changeDisableStatus(true)")
                        input.form-check-input(
                            v-if="currentAction",
                            :checked="currentAction.disabled",
                            type="radio",
                            style="margin-left: 2px; margin-top: -1px"
                        )
                        .span-sm(style="margin-left: 25px") Disabled
                .iconSep
                .button-container(:class="{ disabledButton: isEnd || !hasAnchorArea }", @click="setTimeout")
                    i.fas.fa-history
                    .span-sm Timeout
                .button-container(
                    v-if="!anchor",
                    :class="{ disabledButton: isEnd || retakeAnchorAreaPossible }",
                    :style="anchor ? { 'background-color': 'green' } : undefined",
                    @click="retake"
                )
                    .icon-large.fas.fa-image
                    .span-sm Retake
                .button-container(
                    v-if="anchor",
                    :class="{ disabledButton: isEnd || retakeAnchorAreaPossible }",
                    :style="anchor ? { 'background-color': 'green' } : undefined",
                    @click="device.retakeAnchorArea = false"
                )
                    .icon-large.fas.fa-image
                    .span-sm Retake
                .set-interaction(:class="{ disabledButton: disableInteractionButton }")
                    .set-interaction-top-row
                        .button-container.button-container-interaction(@click="setInteraction('CHECK')")
                            .span-sm &rect; Check
                        .button-container.button-container-interaction(@click="setInteraction('CLICK')")
                            div(style="margin-top: -8px")
                                img(src="https://img.icons8.com/material/12/000000/mouse-left-click.png")
                                span.span-sm Click
                        .button-container.button-container-interaction(@click="setInteraction('RIGHT_CLICK')")
                            div(style="margin-top: -8px")
                                img(src="https://img.icons8.com/material/12/000000/mouse-right-click.png")
                                span.span-sm Right Click
                        .button-container.button-container-interaction(@click="setInteraction('DOUBLE_CLICK')")
                            div(style="margin-top: -6px")
                                img(src="https://img.icons8.com/metro/11/000000/mouse-left-click.png")
                                span.span-sm Double Click
                        .button-container.button-container-interaction(@click="setInteraction('HOVER')")
                            .span-sm
                                i.fas.fa-hand-point-up
                                .span-sm.d-inline(style="padding-left: 3px") Hover
                        .button-container.button-container-interaction(@click="setInteraction('SWIPE_RIGHT')")
                            .span-sm &#8680; Swipe Right
                        .button-container.button-container-interaction(@click="setInteraction('SWIPE_DOWN')")
                            .span-sm &#8681; Swipe Down
                        .button-container.button-container-interaction(@click="setInteraction('FIND_IMAGE')")
                            .span-sm
                                i.fas.fa-search
                                .span-sm.d-inline(style="padding-left: 3px") Find
                        .button-container.button-container-interaction(
                            v-if="OCR_ENABLED",
                            @click="setInteraction('OCR')"
                        )
                            .span-sm
                                i.fas.fa-search
                                .span-sm.d-inline(style="padding-left: 3px") OCR
                    .set-interaction-bottom-row
                        .span-sm Set Interaction
                .iconSep
                    label Branches:
                    br
                    input(placeholder="branch selector" v-model="currentAction.branches" @change="update")
        template(v-if="mode == 'data'")
            .tab-content.tab-pane
                .button-container.button-container-help(@click="$emit('addVariable')")
                    .icon-large.fas.fa-plus
                    .span-sm Add
                .button-container.button-container-help(@click="$emit('addVariable', { hidden:true })")
                    .icon-large.fas.fa-lock
                    .span-sm Password
                .button-container.button-container-help(
                    v-if="PLUGIN_AQUA_URL",
                    :class="{ disabledButton: !importFromAquaId }",
                    @click="$emit('syncAqua', 'import')"
                )
                    .icon-large.fas.fa-file-import
                    .span-sm Import from aqua
                .button-container.button-container-help(
                    v-if="PLUGIN_AQUA_URL",
                    :class="{ disabledButton: !importFromAquaId }",
                    @click="$emit('syncAqua', 'export')"
                )
                    .icon-large.fas.fa-file-export
                    .span-sm Export to aqua
                .button-container.button-container-help(
                    v-if="PLUGIN_AQUA_URL",
                    :class="{ disabledButton: !importFromAquaId }",
                    @click="$emit('syncAqua', 'open')"
                )
                    .icon-large.fas.fa-link
                    .span-sm Open in Aqua
        template(v-if="mode == 'help'")
            .tab-content.tab-pane
                .button-container.button-container-help(@click="$emit('showVideo')")
                    .icon-large.fas.fa-film
                    .span-sm Getting Started
                a.button-container.button-container-help(:href="`${wordpressBase}/documentation`", target="_blank")
                    .icon-large.fas.fa-book
                    .span-sm Documentation
</template>

<style lang="css" scoped>
.tabs {
    display: grid;
    overflow: hidden;
    background-color: whitesmoke;
    border-top: 3px solid #94d904;
}

.table-clipboard {
    display: grid;
    grid-template-columns: max-content max-content;
}

.table {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 20px;
}

.clipboard-textarea-section {
    display: grid;
    grid-template-rows: max-content max-content;
    padding-left: 5px;
}

li.nav-item {
    cursor: pointer;
    user-select: none;
    background-color: whitesmoke;
    max-width: 100px;
}
.navigation {
    display: grid;
    grid-template-columns: auto max-content;
}

.done-button {
    display: grid;
    align-items: center;
    border-bottom: 1px solid var(--tu-border-lightgray);
    background-color: whitesmoke;
}

li.nav-item:nth-child(5) {
    flex: 1;
    text-align: center;
}

li.nav-item > .nav-link.disabled {
    color: #ccc;
}

.tab-content {
    height: 70px;
    max-width: 100vw;
    border-bottom: 1px solid var(--tu-border-lightgray);
}

.tab-pane {
    display: grid;
    grid-template-columns: repeat(19, max-content);
}

.button-container {
    display: grid;
    justify-items: center;
    width: 90px;
    padding-top: 14px;
    cursor: pointer;
    user-select: none;
}

.button-container-interaction {
    justify-items: left;
    width: auto;
    padding: 5px 7px 2px 7px;
    white-space: nowrap;
}

.button-container:hover {
    background-color: #dcdcdc;
}

.button-container:hover:active {
    background-color: #ccc;
}

.button-container-help {
    width: 150px;
}

.button-container-help:nth-child(2) {
    color: var(--black);
    text-decoration: none;
}

.button-container.playing {
    background-color: #dc3545;
    color: white;
}

.button-container.playing:hover {
    background-color: #c82333;
}

.button-container.playing:hover:active {
    background-color: #ad1f2d;
}

.disabledButton {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
}

.justify-items-left {
    justify-items: left;
}

.press-keys-pos {
    margin-left: 110px;
    margin-top: 10px;
}

.padding-reduce {
    padding-top: 5px;
    padding-left: 5px;
    width: 80px;
    white-space: nowrap;
}

.set-interaction {
    display: grid;
    grid-template-rows: max-content auto;
    border-left: 1px solid var(--tu-border-lightgray);
    padding: 1px;
}

.set-interaction-top-row {
    display: grid;
    grid-template-columns: repeat(5, max-content);
}

.set-interaction-bottom-row {
    display: grid;
    justify-items: center;
    margin-top: 5px;
}

.iconSep {
    background-color: lightgray;
    width: 1px;
}

.input-size {
    height: 20px;
    width: 78px;
    font-size: 10px !important;
    text-align: center;
}

.play-it-icon-size {
    font-size: 150%;
    margin-bottom: 2px;
}

.browser-actions-pos {
    margin-left: 30px;
    margin-top: 10px;
}

.moreKeys {
    display: grid;
    padding-top: 10px;
}

.dropdown-menu {
    display: block;
    min-width: 160px;
    background-color: whitesmoke;
    position: absolute;
    transform: translate3d(-170px, 0px, 0px);
    top: 0;
    left: 0;
}

.move-left {
    justify-items: left;
    margin-left: 10px;
}

.wide-item {
    width: 140px;
}

.dropdown-pos {
    position: absolute;
    text-align: center;
}

.icon-mg {
    margin-left: 50px;
}

.complain-once {
    pointer-events: none;
}

.icons-left {
    justify-items: left;
}

.alignMoreMenu {
    white-space: pre;
}

.align-mg {
    margin-top: -7px;
}

.custom-align {
    padding-left: 3.5rem;
}
</style>
